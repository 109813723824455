﻿@import "variables";

.nav {
    position: fixed;
    bottom: 0;
    z-index: 1;

    width: 100%;
    background: theme-color("primary");
    padding-bottom: constant(safe-area-inset-bottom, .5rem);
    padding-bottom: env(safe-area-inset-bottom, .5rem);

    .list {
        height: 4.5rem;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        width: 100%;


        .item {
            outline: 0;
            cursor: pointer;
            flex: 1 1 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: .5;
            transition: $transition-default;

            &.active {
                opacity: 1;
            }

            &:hover, &:focus {
                opacity: 1;
            }


            .link, {
                position: relative;
                @extend small;
                text-align: center;
                color: shades("white");
                text-decoration: none;
                transition: $transition-default;

                &:hover, &:focus {
                    text-decoration: none;
                }

                svg {
                    fill: shades("white");
                    height: 1.75rem;
                    width: 1.75rem;
                    margin-bottom: 0;
                }


                .text {
                    display: block;
                    margin-top: .25rem;
                }

                .badge {
                    position: absolute;
                    top: 4px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    font-size: 0.75rem;
                    color: theme-color("primary");
                }
            }
        }
    }
}
