@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "variables";

$medium-color: #7E7E7E;
$red-color: $main_color;

.padding-section {
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include media-breakpoint-up(md) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    @media(max-width: 567px) {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    &--top {
        padding-top: 1rem;

        @include media-breakpoint-up(md) {
            padding-top: 2rem;
        }
    }

    &--bottom {
        padding-bottom: 1rem;

        @include media-breakpoint-up(md) {
            padding-bottom: 2rem;
        }
    }
}

app-timeline-town, app-timeline-detail, app-timeline-edit, app-article-detail, app-article-edit, app-articles, app-survey-answers, app-survey-edit {
    .detail {
        @extend .padding-section;

        &__block {
            background: rgba(white, .8);
            padding: 1rem;
            position: relative;
            background-color: #F3F2F7;

            @media (min-width: 370px) {
                padding: 1.25rem;
            }

            @include media-breakpoint-up(sm) {
                padding: 1.5rem;
            }
        }

        &__image {
            padding-top: 56.25%;
        }

        &__title {
            @extend .h2;
            margin-bottom: .25rem;
        }

        &__details {
            color: $red-color;
            font-size: .75rem;
            margin-bottom: 1.5rem;
            display: flex;
            flex-flow: row wrap;
        }

        &__date {
            &::after {
                content: "|";
                margin: 0 .25rem;
            }
        }
    }


    .items {

        &.empty {
            justify-content: center;
        }

        .not-found {
            max-height: 0;
        }

        display: flex;
        flex-direction: column;

        .title {
            margin-top: 17px;
        }

        .item-list {
            height: 100%;
            flex: 1 1;

            .scrollable-content {
                height: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                position: relative;
                margin: 20px auto;
                @include make-container();
                @include make-container-max-widths();
                @media(max-width: 567px) {
                    padding-left: .5rem;
                    padding-right: .5rem;
                }

                h2 {
                    position: absolute;
                    top: -60px;
                }

                .item {
                    border: solid 1px rgba(15, 0, 100, 0.15);
                    border-left: 6px solid #0F0064;
                    width: calc(33.3333333% - .34rem);
                    min-width: 250px;
                    background: none;
                    cursor: pointer;
                    margin-bottom: .5rem;
                    position: relative;
                    height: fit-content;

                    &:nth-child(3n+1), &:nth-child(3n+2) {
                        margin-right: .5rem;
                    }

                    @media(max-width: 992px) {
                        width: calc(50% - .33rem);
                        &:nth-child(2n+0) {
                            margin-right: 0;
                        }
                        &:nth-child(2n+1) {
                            margin-right: .5rem;
                        }
                    }

                    @media(max-width: 767px) {
                        width: 100%;
                        margin-right: 0 !important;
                    }

                    .detail__block {
                        height: 110px;
                        overflow: hidden;
                        justify-content: center;
                        padding-top: 0;
                        padding-bottom: 0;
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        h3 {
                            @extend h5;
                            max-height: 57px;
                            overflow: hidden;
                            position: relative;
                            border-bottom: solid 1px rgba(15, 0, 100, 0.15);
                            padding-bottom: 15px;

                            &.unread {
                                padding-left: 10px;
                                color: $red-color;

                                &:before {
                                    z-index: 5;
                                    content: ' ';
                                    display: block;
                                    background-color: $red-color;
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 3px;
                                    position: absolute;
                                    top: 11px;
                                    left: 0;
                                }
                            }
                        }

                        &.important {
                            padding-left: 3rem;
                            color: $red-color;

                            &:before {
                                position: absolute;
                                top: 0;
                                left: 0;
                                content: ' ';
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-image: url('/assets/logos/alert.svg');
                                width: 3rem;
                                height: 100%;
                                background-position: center;
                                fill: $red-color;
                            }
                        }
                    }

                    .details {
                        color: $red-color;
                        font-size: .75rem;
                        margin-bottom: 0;
                        display: flex;
                        justify-content: space-between;
                        flex-flow: row wrap;

                        > .sub-title {
                            font-size: 1.25rem;
                            font-weight: 500;
                        }

                        h3 {
                            text-align: justify;
                        }

                        .detail__block {
                            padding: 1.25rem;
                        }
                    }
                }
            }
        }
    }

    .item-images {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;


        @media(max-width: 767px) {
            margin: 0 -.75rem;
        }

        > app-codalt-image {
            cursor: pointer;
            margin-bottom: .5rem;
            width: calc(50% - .33rem);

            &:nth-child(2n+0) {
                margin-right: 0;
            }

            &:nth-child(2n+1) {
                margin-right: .5rem;
            }

            &:before {
                padding-top: 65%;
                content: ' ';
                display: block;
            }

            @media(max-width: 767px) {
                width: 100%;
                margin-right: 0 !important;
            }
        }
    }

    .item-edit {

        .save-button {
            position: fixed;
            bottom: 85px;
            right: 20px;
        }

        .file-button {
            position: fixed;
            bottom: 155px;
            right: 15px;
        }

        .main-image {
            width: 100%;
            height: 300px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            app-codalt-image {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

            file-manager {
                position: absolute;
                bottom: 25px;
                left: 25px;
            }

            .info {
                color: #FFFFFF;
                text-shadow: 1px 1px 3px #000000;
                padding: 25px;
            }
        }

        .images {
            display: flex;
            flex-direction: column;
            margin: -5;
            justify-content: center;

            > div {
                padding: 5px;
                position: relative;

                app-codalt-image {
                    width: 300px;
                    height: 200px;
                    @media(min-width: 768px) {
                        width: 500px;
                        height: 300px;
                    }
                    transition: opacity 250ms;

                    > button {
                        opacity: 0;
                    }

                    &:hover {
                        > button {
                            opacity: 1;
                        }
                    }
                }

                button {
                    position: absolute;
                    left: 10px;
                }
            }

            .image-edit {
                position: relative;
                display: flex;
                align-items: center;
                justify-items: center;
                justify-content: center;

                .drag-handle {
                    padding: 20px;
                    background: rgba(0, 0, 0, 0.5);
                    color: #FFFFFF;
                    right: 10px;
                    position: absolute;
                    text-align: center;

                    i.fa {
                        font-size: 2rem;
                    }
                }
            }
        }
    }

    .daterange {
        padding-top: 8px;
        @media(max-width: 767px) {
            &:nth-of-type(odd) {
                background-color: rgba(theme-color("primary"), 0.1);
            }
        }
        @media(min-width: 768px) {
            &:hover {
                background-color: rgba(theme-color("primary"), 0.1);
            }
        }

    }

    .mat-fab, .mat-mini-fab {
        font-size: 20px;
    }

    .add-floating-button {
        position: fixed !important;
        bottom: 85px;
        right: 15px;
        bottom: calc(85px + env(safe-area-inset-bottom, 0));
        right: calc(20px + env(safe-area-inset-right, 0));

    }

    .delete-button {
        position: fixed !important;
        bottom: 160px;
        right: 15px;
        bottom: calc(155px + env(safe-area-inset-bottom, 0));
        right: calc(20px + env(safe-area-inset-right, 0));
        background-color: theme-color("secondary") !important;
    }

    .copy-button {
        position: fixed !important;
        bottom: 235px;
        right: 15px;
        bottom: calc(235px + env(safe-area-inset-bottom, 0));
        right: calc(15px + env(safe-area-inset-right, 0));
        background-color: theme-color("secondary") !important;
    }

    .floating-buttons {
        position: fixed !important;
        bottom: 85px;
        right: 15px;
        bottom: calc(85px + env(safe-area-inset-bottom, 0));
        right: calc(20px + env(safe-area-inset-right, 0));
        display: flex;
        flex-direction: column;

        button {
            margin-top: 15px;

            &:not(:last-child) {
                background-color: theme-color("secondary") !important;
            }
        }

        transition: opacity 350ms;

        &.hide {
            opacity: 0.05;
            pointer-events: none;
        }
    }
}
