$main_color: #0F0064;
$sec_color: #85D1E4;


$theme-colors: (
        "primary": $main_color,
        "secondary": $sec_color,
);

$shades: (
        "black": #000000,
        "dark": #262d33,
        "default": #8199ab,
        "light": #f2f4f6,
        "white": #ffffff,
);


@function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
}

@function shades($key: "default") {
    @return map-get($shades, $key);
}

$transition-default: .3s;