@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?p504gq');
  src: url('fonts/icomoon.eot?p504gq#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?p504gq') format('truetype'),
  url('fonts/icomoon.woff?p504gq') format('woff'),
  url('fonts/icomoon.svg?p504gq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e900";
  color: #fff;
}

.icon-angle:before {
  content: "\e901";
  color: #0f0064;
}

.icon-angle-down:before {
  content: "\e902";
  color: #0f0064;
}

.icon-battery:before {
  content: "\e903";
  color: #0f0064;
}

.icon-camera:before {
  content: "\e904";
  color: #fff;
}

.icon-camera-dark:before {
  content: "\e904";
  color: #0f0064;
}

.icon-car:before {
  content: "\e905";
  color: #0f0064;
}

.icon-car-white:before {
  content: "\e906";
  color: #fff;
}

.icon-check:before {
  content: "\e907";
  color: #0f0064;
}

.icon-close:before {
  content: "\e908";
  color: #0f0064;
}

.icon-close_white:before {
  content: "\e909";
  color: #fff;
}

.icon-current .path1:before {
  content: "\e90a";
  color: rgb(0, 0, 0);
}

.icon-current .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-current .path3:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(103, 198, 221);
}

.icon-exit:before {
  content: "\e90d";
  color: #fff;
}

.icon-geo:before {
  content: "\e90e";
  color: #0f0064;
}

.icon-handicap:before {
  content: "\e90f";
  color: #0f0064;
}

.icon-home:before {
  content: "\e910";
  color: #fff;
}

.icon-map:before {
  content: "\e911";
  color: #fff;
}

.icon-more .path1:before {
  content: "\e912";
  color: rgb(15, 0, 100);
}

.icon-more .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(15, 0, 100);
}

.icon-more .path3:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-netherlands:before {
  content: "\e915";
  color: #fff;
}

.icon-satellite:before {
  content: "\e916";
  color: #fff;
}

.icon-search:before {
  content: "\e917";
  color: #fff;
}

.icon-settings:before {
  content: "\e919";
  color: #fff;
}

.icon-sync:before {
  content: "\e91b";
  color: #fff;
}

.icon-target:before {
  content: "\e91c";
  color: #fff;
}

.icon-target_map:before {
  content: "\e91d";
  color: #0f0064;
}

.icon-target_map_white:before {
  content: "\e91e";
  color: #fff;
}

.icon-town:before {
  content: "\e91f";
  color: #fff;
}

.icon-zoomin:before {
  content: "\e920";
  color: #fff;
}

.icon-zoomout:before {
  content: "\e921";
  color: #fff;
}

.icon-clubs:before {
  content: "\e918";
}

.icon-bullhorn:before {
  content: "\e91a";
}

.icon-forward:before {
  content: "\e969";
}

.icon-more-custom {
  width: 14px;
  height: 14px;
  background-size: contain;
  background: url("../assets/images/icons/more.svg") no-repeat;
  margin: 0px 15px 0px 0px;
  display: inline-block;
}
